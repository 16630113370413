<template>
    <v-container fluid>

        <v-row class="fill-height" align="center" justify="center">

            <div class='impressum'>
            
        
                <p class="display-1 primary--text">Datenschutz</p>

                <p class="title">1. Geltungsbereich</p>

                <p>Diese Datenschutzerklärung klärt Nutzer über die Art, den Umfang und den Zweck der Verarbeitung personenbezogener Daten durch den Verantwortlichen gem. Art. 4 Nr. 7 DSGVO auf.</p>

                <p>
                    Verantwortlich für die Durchführung der Studie @myTabu ist die
                </p>

                <p>
                    Universitätsmedizin Göttingen<br> 
                    Klinik für Psychiatrie und Psychotherapie – Forensische Psychiatrie<br> 
                </p> 

                <p>unter der Leitung von:</p>
                
                <p>
                    Herrn Prof. Dr. Jürgen Müller und Dr. Peter Fromberger<br>
                    Rosdorfer Weg 70<br>
                    37081 Göttingen<br> 
                    Tel.: 0551-4022100<br>
                    Email: j.mueller@med.uni-goettingen.de oder peter.fromberger@medizin.uni-goettingen.de
                </p>

                <p>Die rechtlichen Grundlagen des Datenschutzes finden sich in der Datenschutzgrundverordnung (DSGVO) im Bundesdatenschutzgesetz (BDSG), im niedersächsischen Datenschutzgesetz 
                (NdsDSG) und dem Telemediengesetz (TMG). Die UMG erklärt, sich an die anwendbaren Gesetze insbesondere beim Verarbeiten von personenbezogenen Daten zu halten.</p>

                <p>Den zuständigen Datenschutzbeauftragten für die Studie erreichen Sie unter:</p> 

                <p>
                    Universitätsmedizin Göttingen<br>
                    -Datenschutzbeauftragter-<br>
                    37099 Göttingen<br>
                    Telefon: 0551 3922762<br>
                    E-Mail: datenschutz@med.uni-goettingen.de
                </p>

                <p>Die für die UMG im Rahmen des Datenschutzes zuständige Datenschutz-Aufsichtsbehörde ist:</p>

                <p>
                    Die Landesbeauftragte für den Datenschutz Niedersachsen<br>
                    Prinzenstraße 5<br>
                    30159 Hannover<br>
                    Telefon: 0511 120 45 00<br>
                    Telefax: 0511 120 45 99<br>
                    E-Mail: poststelle@lfd.niedersachsen.de
                </p>

                <p class="title">2. Umgang mit personenbezogenen Daten</p>

                <p><b>Personenbezogene Daten</b> sind alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person beziehen; als identifizierbar wird eine natürliche Person 
                angesehen, die direkt oder indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung oder zu einem oder 
                mehreren besonderen Merkmalen identifiziert werden kann, die Ausdruck der physischen, physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen oder sozialen Identität 
                dieser natürlichen Person sind (Art. 4 Nr. 1 DSGVO).</p>

                <p>Das Gesetz bezieht sich auf das Verarbeiten von personenbezogenen Daten:</p>

                <p><b>Verarbeitung</b> ist jeder mit oder ohne Hilfe automatisierter Verfahren ausgeführte Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten wie das Erheben, das 
                Erfassen, die Organisation, das Ordnen, die Speicherung, die Anpassung oder Veränderung, das Auslesen, das Abfragen, die Verwendung, die Offenlegung durch Übermittlung, Verbreitung oder 
                eine andere Form der Bereitstellung, der Abgleich oder die Verknüpfung, die Einschränkung, das Löschen oder die Vernichtung (Art. 4 Nr. 2 DSGVO).</p>

                <p>Personenbezogene Daten werden von der UMG im Rahmen der Online-Intervention @myTabu nur dann verarbeitet, wenn die Nutzer in die Verarbeitung einwilligen (s. dazu Art. 6 DSGVO). 
                Die Einwilligung erfolgte im Rahmen der Einwilligung zur Teilnahme an der Studie.</p>

                <p class="title" v-if="usergroup == 'Supervisor'">Erklärung zum Datenschutz (entsprechend der Einverständniserklärung)</p>
                <p class="title" v-if="usergroup == 'Client'">Erklärung zum Datenschutz (entsprechend der Probandeneinwilligung)</p>

                <ol v-if="usergroup == 'Client'">
                    <li>Die Teilnahme an der Studie ist freiwillig. Ihnen entsteht kein Nachteil für Ihre Bewährungszeit oder Führungsaufsicht, wenn Sie nicht an dieser Studie teilnehmen.</li>
                    <li>Sie können Ihre Einwilligung jederzeit mündlich oder schriftlich widerrufen. Im Falle des Widerrufs werden keine weiteren Daten mehr erhoben.</li>
                    <li>Die Daten werden ausschließlich zu wissenschaftlichen Zwecken erhoben. Dabei werden die anerkannten ethischen Standards der wissenschaftlichen Forschung eingehalten. Die Rechtsgrundlage für 
                        die Datenverarbeitung ist diese Einwilligungserklärung. 
                    </li>
                    <li>Ihre Daten werden lediglich in Verbindung mit einem Pseudonymisierungscode elektronisch gespeichert. Sie erhalten einen persönlichen Code, sodass man Ihre Angaben nicht in Verbindung
                         mit Ihrem Namen bringen kann. Nur Ihr/Ihre Bewährungshelfer/in hat darauf Zugriff und kann Ihr Pseudonym mit Ihrem Namen in Verbindung bringen. Ihr/Ihre Bewährungshelfer/in unterliegt 
                         der Schweigepflicht im Rahmen der gesetzlichen Bestimmungen und der von Ihnen unterschriebenen Einwilligungserklärung. Sie haben eingewilligt, dass die Daten nach Beendigung oder Abbruch der 
                         Studie gemäß guter klinischer Praxis mindestens 10 Jahre aufbewahrt werden dürfen.
                    </li>
                    <li>Die Veröffentlichung der Daten zu Forschungszwecken ist geplant. Bei der Veröffentlichung der Forschungsdaten werden alle Informationen anonymisiert dargestellt, 
                        sodass zu keinem Zeitpunkt ein Rückschluss auf Ihre Person möglich ist. Es steht zu keinem Zeitpunkt das Einzelergebnis einer Versuchsperson im Interesse und es wird zu 
                        keinem Zeitpunkt das Einzelergebnis einer Versuchsperson veröffentlicht. 
                    </li>
                    <li>Sie haben jederzeit das Recht, Auskunft über die gespeicherten Daten zu erhalten, einschließlich unentgeltlicher Überlassung einer Kopie (Art. 15 DSGVO ), sofern die Auskunftserteilung 
                        keinen unverhältnismäßigen Aufwand erfordern würde (§ 27 Abs. 2 BDSG ) oder personenbezogene Daten korrigieren (das Recht auf Berichtigung unrichtiger Daten, Art. 16 DSGVO, wird gemäß 
                        Art. 89 DSGVO eingeschränkt) oder löschen zu lassen (Art. 17 DSGVO). Sie können Ihr Einver-ständnis zur Speicherung personenbezogener Daten gemäß Art. 21 Abs. 1 DSGVO jederzeit widerrufen. 
                        Im Falle des Widerrufs werden alle gespeicherten personenbezogenen Daten im Rahmen der technischen Möglichkeiten gelöscht. Ebenfalls können Sie gegen die Verarbeitung Ihrer personenbezogenen 
                        Daten Widerspruch (Art. 21 DSGVO) einlegen. Sie haben außerdem das Recht, die Datenverarbeitung einzuschränken (Art. 18 DSGVO) oder die Daten zu übertragen (Art. 20 DSGVO). Das 
                        Widerspruchsrecht (Art. 18 DSGVO) ist ebenso wie das Recht auf Widerspruch zur Verarbeitung personenbezogener Daten (Art. 21 DSGVO) jedoch insoweit beschränkt, als diese Rechte 
                        voraussichtlich die Verwirklichung der Forschungs- oder Statistikzwecke unmöglich machen oder ernsthaft beinträchtigen und die Beschränkung bzw. der Widerspruch für die Erfüllung 
                        der Forschungs- oder Statistikzwecke notwendig ist (§27 BDSG Abs. 2).
                    </li>
                    <li>Ihr/Ihre Bewährungshelfer/in kann auf bestimmte Angaben, die sie während des Online-Programms machen, zugreifen und kann diese anhand Ihres Pseudonymisierungscodes Ihrer Person zuweisen. 
                        Dadurch kann Ihr/Ihre Bewährungshelfer/in frühzeitig mit Ihnen Kontakt auf-nehmen, sollten sich Gefahren für Sie oder die Allgemeinheit abzeichnen und Ihnen dabei helfen, weiter straffrei 
                        zu bleiben. Ihr/Ihre Bewährungshelfer/in hat aber zu keinem Zeitpunkt Zugriff auf Ihre Nachrichten an den Coach, über kritische Ereignisse hinausgehende andere Angaben in Fragebögen oder 
                        Inhalte des Online-Programms (z. B. Angaben, die Sie in Übungen machen). Er/Sie ist verpflichtet, keine Daten des Forschungsprojekt an andere Personen weiterzugeben, er/sie ist aber auch 
                        gesetzlich dazu verpflichtet, konkrete Gefahren für die Allgemeinheit oder für Sie selbst, abzuwenden. Sie haben hier die gleichen Rechte wie bereits unter Punkt 6 beschrieben.
                    </li>
                    <li>Sie haben eingewilligt, dass im Falle unerwünschter Ereignisse, die erhobenen Daten pseudonymisiert (verschlüsselt) an die jeweils zuständige Ethik-Kommission weitergegeben werden können.</li>
                    <li>Durch die Studiendurchführenden wird eine Aktenanalyse vorgenommen. Dazu gehört auch die Nachfrage beim Bundesamt für Justiz, ob Sie nach Ihrer Teilnahme an @myTabu im Zeitraum von 
                        5 Jahren mit dem Gesetz in Konflikt geraten sind. Die primäre Aktenanalyse erfolgt ausschließlich in den Räumlichkeiten der Bewährungshilfe, beide Teile erfolgen unter Angabe Ihres 
                        persönlichen Codes, sodass anhand der erhobenen Daten kein direkter Rückschluss auf Ihren Namen möglich ist. Für alle im Rahmen der Aktenanalyse erhobenen Daten gelten die gleichen 
                        benannten Rechte wie für die Online-Befragungen (siehe Punkt 8). 
                    </li>
                </ol>

                <ol v-if="usergroup == 'Supervisor'">
                    <li>Ihre Mitarbeit an der Studie ist freiwillig.</li>
                    <li>Sie können Ihre Einwilligung jederzeit mündlich oder schriftlich widerrufen. Im Falle des Widerrufs werden keine weiteren Daten mehr erhoben.</li>
                    <li>Die Daten werden ausschließlich zu wissenschaftlichen Zwecken erhoben. Dabei werden die anerkannten ethischen Standards der wissenschaftlichen Forschung eingehalten. Die Rechtsgrundlage für 
                        die Datenverarbeitung ist diese Einwilligungserklärung. 
                    </li>
                    <li>Ihre Daten werden in Verbindung mit einem Pseudonymisierungscode Ihrer Klienten elektronisch gespeichert. Zudem erhalten Sie ein eigenes Pseudonym, das gemeinsam mit Ihrem Namen, 
                        Ihrer dienstl. Adresse, Ihrer dienstl. Emailadresse und ihrer dienstl. Telefonnummer gespeichert wird. Nach Abschluss der Studie, sobald die bei Ihnen während der Studiendurchführung 
                        liegende kleine Entblindungsliste an die Studienleitung gesendet wurde, werden umgehend alle Daten gelöscht, sodass man Ihre Angaben nicht mehr in Verbindung mit Ihrem Namen bringen kann.
                    </li>
                    <li>Die Veröffentlichung der Daten zu Forschungszwecken ist geplant. Bei der Veröffentlichung der Forschungsdaten werden alle Informationen anonymisiert dargestellt, 
                        sodass zu keinem Zeitpunkt ein Rückschluss auf Ihre Person möglich ist. Es steht zu keinem Zeitpunkt das Einzelergebnis einer Versuchsperson und/oder ihres Bewährungshelfenden im Interesse.
                    </li>
                    <li>Ihre Daten werden gemäß den Richtlinien guter wissenschaftlicher Praxis mindestens 10 Jahre nach Abschluss der Studie gespeichert. Es wird eine Version der Daten mit dem Pseudonym der 
                        Klienten gespeichert, sodass bei Bedarf zu einem späteren Zeitpunkt darauf zugegriffen werden kann. 
                    </li>
                    <li>Sie haben jederzeit das Recht, Auskunft über die gespeicherten Daten zu erhalten, einschließlich unentgeltlicher Überlassung einer Kopie (Art. 15 DSGVO ), sofern die Auskunftserteilung 
                        keinen unverhältnismäßigen Aufwand erfordern würde (§ 27 Abs. 2 BDSG ) oder personenbezogene Daten korrigieren (das Recht auf Berichtigung unrichtiger Daten, Art. 16 DSGVO, wird gemäß 
                        Art. 89 DSGVO eingeschränkt) oder löschen zu lassen (Art. 17 DSGVO). Sie können Ihr Einver-ständnis zur Speicherung personenbezogener Daten gemäß Art. 21 Abs. 1 DSGVO jederzeit widerrufen. 
                        Im Falle des Widerrufs werden alle gespeicherten personenbezogenen Daten im Rahmen der technischen Möglichkeiten gelöscht. Ebenfalls können Sie gegen die Verarbeitung Ihrer personenbezogenen 
                        Daten Widerspruch (Art. 21 DSGVO) einlegen. Sie haben außerdem das Recht, die Datenverarbeitung einzuschränken (Art. 18 DSGVO) oder die Daten zu übertragen (Art. 20 DSGVO). Das 
                        Widerspruchsrecht (Art. 18 DSGVO) ist ebenso wie das Recht auf Widerspruch zur Verarbeitung personenbezogener Daten (Art. 21 DSGVO) jedoch insoweit beschränkt, als diese Rechte 
                        voraussichtlich die Verwirklichung der Forschungs- oder Statistikzwecke unmöglich machen oder ernsthaft beinträchtigen und die Beschränkung bzw. der Widerspruch für die Erfüllung 
                        der Forschungs- oder Statistikzwecke notwendig ist (§27 BDSG Abs. 2).
                    </li>
                    <li>Durch die Studiendurchführenden wird eine Aktenanalyse der von Ihnen betreuten Klienten vorgenommen. Die primäre Aktenanalyse erfolgt ausschließlich in den Räumlichkeiten der 
                        Bewährungshilfe und unter Angabe des Pseudonymisierungscodes Ihres Klienten, sodass anhand der erhobenen Daten kein direkter Rückschluss auf Ihren Namen als Bewährungshelfer möglich ist. 
                        Für alle im Rahmen der Aktenanalyse erhobenen Daten gelten die gleichen benannten Rechte wie für die personenbezogenen Daten (siehe Punkt 7). 
                    </li>
                </ol>

            </div>

        </v-row>

    </v-container>
</template>

<script>
export default {
    name: 'data-security',

    data: () => ({
        //
    }),

    created() {


    },

    computed: {
        usergroup() {
            return this.$store.getters['store_auth/get_user_group']
        },
    }
}
</script>

<style scoped>

</style>